.logoImg {
  width: 50px;
  height: 50px;
  border-radius: 2rem;
}

.logoTitle {
  font-size: 1.5rem;
  color: #000;
  font-family: 'MS Madi', serif;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  gap: 0.5rem;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

}

.icon {
  height: 1.5rem;
    width: 1.5rem;
}

.navlink {
  font-family: 'Cinzel', serif;
    font-size: 1.5rem;
}

@media screen and (min-width: 768px) {
  .drawer {
    display: none;
  }
  .logo {

  }


}
