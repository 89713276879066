

    $primary-color: #F2F2F2;
    $secondary-color: #F2AED4;
    $tumbleweed: #F1B69Cff;
    $rocket-metallic: #837672ff;
    $blue: #B8E3EA;
    $white: #ffffff;
    $black: #000000;
    $grey: #F2F2F2;

    $font-family: 'Montserrat', sans-serif;
