@use '../../styles/_variables.scss' as *;


.banner__container {
  background: $secondary-color;
  padding: 3rem;
}

.banner__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (min-width: 800px) {
  .banner {
    background-color: $secondary-color;
  }
  .banner__img {
    display: flex;
  }
}

@media only screen and (min-width: 1024px) {

    .banner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem;
    }

    .banner__img {
        width: 50%;
        height: 100%;
        object-fit: cover;
    }

    .banner__content {
      display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}



.frase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.frase p {
  font-size: 2rem;
  color: $primary-color;
  margin: 10px;

}

.frase span {
  font-size: 1rem;
  color: $primary-color;
  margin: 10px;

}

.banner__img {
  width: 100%;
  height: 100%;
}

.banner__img img {
  width: 100%;
  height: 100%;
}

.banner__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}