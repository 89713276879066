@use '../../styles/_variables.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .titleDiv {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .titletext {
    font-style: italic;
  }
}

.treatment__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  color: $black;
  font-weight: normal;
  line-height: 1.5rem;
  height: 100%;
  padding: 2rem;
  gap: 1rem;


  .tumbleweed {
    background-color: $tumbleweed;
  }

  .grey {
    background-color: $grey;
  }

  .treatment__name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .treatment__description {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .treatment__button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}


.tumbleweed {
    background-color: $tumbleweed;
}

.blue {
    background-color: $blue;
}

.grey {
    background-color: $grey;
}

@media only screen and (min-width: 1024px) {
    .container {
        .titleDiv {
        margin-top: 4rem;
        margin-bottom: 2rem;
        }

        .titletext {
        font-size: 2rem;
        }
    }

    .treatment__container {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 2rem 4rem;
        gap: 2rem;

        .treatment__name {
        font-size: 2rem;
        }

        .treatment__description {
        font-size: 1.5rem;
        }

        .treatment__button {
        margin-top: 1rem;
        margin-bottom: 1rem;
        }
    }

}