@import url('../../styles/_variables.scss');

$secondary-color: #F2AED4;
$tumbleweed: #F1B69Cff;


.form_checkbox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.titulo {
  color: $secondary-color;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;

}

.subtitulo {
  color: $tumbleweed;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  align-self: flex-start;
}


.patientform__container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #f5f5f5;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}


.form_pessoal {
  display: flex;
    flex-direction: column;
    gap: 1rem;

}
.form_profissional {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.form_contato {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.form_endereco {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}


.form_endereco_int {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form_responsavel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}




.form_obs {
  width: 100%;
}

.form_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.error {
  color: red;
  font-size: 1rem;
  font-weight: bold;
}

.error__div {
  margin: 1rem;
  padding: 1rem;
  border: red solid 1px;
}

//
//.form_pessoal {
//  display: grid;
//  grid-template-columns: 4fr 1fr 1fr;
//  grid-column-gap: 0.5rem;
//  grid-row-gap: 0.5rem;
//
//}
//
//
//.form_profissional {
//  display: grid;
//  grid-template-columns: 1fr 1fr 1fr 1fr;
//  grid-column-gap: 0.5rem;
//  grid-row-gap: 0.5rem;
//  width: 100%;
//}
//
//.form_contato {
//  display: grid;
//  grid-template-columns: 2fr 1fr 1fr;
//  grid-column-gap: 0.5rem;
//  grid-row-gap: 0.5rem;
//  width: 100%;
//
//}
//
//.form_endereco {
//  display: grid;
//  grid-template-columns: 3fr 1fr 1fr;
//  grid-column-gap: 0.5rem;
//  grid-row-gap: 0.5rem;
//  width: 100%;
//
//
//}
//
//.form_endereco_int {
//  display: grid;
//  grid-template-columns: 1fr 1fr 1fr 1fr;
//  grid-column-gap: 0.5rem;
//  grid-row-gap: 0.5rem;
//  width: 100%;
//
//}
//
//.form_responsavel {
//  display: grid;
//  grid-template-columns: 1fr 1fr 1fr;
//  grid-column-gap: 0.5rem;
//  grid-row-gap: 0.5rem;
//  width: 100%;
//
//}