@use '../../styles/_variables.scss' as *;


.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, $grey 75%, $white 50%);
  padding: 1rem;

}

.title {
  margin-top: 1rem;
  font-weight: normal;
  font-style: italic;
  margin-bottom: 0.5rem;

}

.name {

}

.text {
  text-align: justify;
  color: $black;
  font-weight: normal;
  line-height: 1.5rem;

}

.arrow__icon {
  color: $black;
  font-size: 2rem;
  margin: 1rem;
}

.photo {
  width: 60%;
  height: 60%;
  object-fit: cover;
  align-self: flex-end;
  margin-right: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}


@media only screen and (min-width: 1024px) {
    .about__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem;

    }

    .title {
        display: none;
    }

    .name {
        font-size: 2rem;
    }

    .text {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .photo {
        width: 30%;
        height: 30%;
        margin-top: 0;
        margin-bottom: 0;
    }

}
