@use '../../styles/_variables.scss' as *;

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  height: 5rem;

  gap: 1rem;
}

.footer__icons {
  display: flex;
  gap: 1rem;
  color: $blue;
}

.footer__text {
  color: $blue;
  font-size: 0.8rem;
}

.fab {
  position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 100;
  color: $grey;
  background-color: $blue;
}

@media only screen and (min-width: 1024px) {
    .footer {
        height: 5rem;
    }

    .footer__icons {
        gap: 2rem;
      scale: 2;
    }

    .footer__text {
        font-size: 2rem;
    }
}
