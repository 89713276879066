@use '../../../styles/_variables.scss' as *;

.qualifications {
display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
  background-color: $blue;
}

.title {
  margin-top: 2rem;
    margin-bottom: 1rem;
  font-weight: normal;
  font-style: italic;

}

.qualifications__container
{
  padding: 0 1rem;

}

.qualifications__item {
  margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  gap: 0.2rem;
}

.qualifications__item__title {

}


.qualifications__item__description {
  font-style: italic;
}